import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { Button, Card, CardBody, Collapse, Modal, ModalBody, ModalHeader } from "reactstrap";
import { UserInterfaceAction, UserInterfaceActionType } from "../../../redux/actions/userInterfaceAction";
import { getValidationMessage, isClientLoggedIn, setOpacityOnHide } from "../../../components/utils";

import { ActionType } from "../../../redux/actions";
import { Alerts } from "../../../alerts";
import { CMSAware } from "../../../containers/CmsProvider";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { CrpProps } from "../../dynamic/typesearchContainer/TypesearchContainerWidget";
import { Dispatch } from "redux";
import { EditMode } from "../../../components/generic-form/form.types";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { PageWidgetBaseProps } from "../../page/pageWidget.types";
import { PermissionType } from "@maxxton/cms-mxts-api";
import { RevealerAction } from "../../../redux/actions/RevealerAction";
import { State } from "../../../redux";
import { StringUtil } from "../../../utils/string.util";
import { Unit } from "../../mxts";
import { UserInterfaceState } from "../../../redux/reducers/userInterfaceReducer";
import { WidgetOptions } from "./";
import { connect } from "react-redux";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { injectPropIntoElementTrees } from "../../resultsPanel/resultsPanelUtil";
import { isEqual } from "lodash";
import loadable from "@loadable/component";
import { loadableRetry } from "../../../utils/loadableComponents.util";
import { wrapProps } from "../../../i18n";

const GenericInput = loadable(() => loadableRetry(() => import("../../../components/generic-form/input")), {
    resolveComponent: ({ GenericInput }) => GenericInput,
});

interface RevealerBaseProps extends PageWidgetBaseProps<WidgetOptions> {
    childs: JSX.Element[];
    className: string;
    formAlerts?: Alerts;
    friendlyUrl?: string;
    id: string;
    initialItem?: any;
    item?: any;
    mode?: EditMode;
    onChange?: (newVal: any, prop: any, formModalEdit?: string) => void;
    root?: any;
    showLess: string;
    showMore: string;
    validate?: (id: string, isValid: boolean) => void;
    validations?: {
        [id: string]: {
            isValid: boolean;
            message?: string;
        };
    };
    permission: PermissionType;
    unit?: Unit;
}

interface RevealerStoreProps {
    revealerId: string;
    useConditionalStepFlow: boolean;
    selectedCriteriaCount?: number;
    myEnvState: MyEnvState;
    userInterfaceState: UserInterfaceState;
}

interface RevealerDispatchProps {
    dispatchAction: Dispatch<RevealerAction | UserInterfaceAction>;
}

interface RevealerProps extends CMSProvidedProperties, RevealerBaseProps, RevealerStoreProps, RevealerDispatchProps, CrpProps {}

interface RevealerState {
    childsWithoutContent: JSX.Element[];
    expand: boolean;
    disableWidget: boolean;
    showPopupDelayState: boolean;
    showPopup: boolean;
    reachedScrollPopupHeight: boolean;
    exitIntentPopupState: boolean;
    feedbackPopup: boolean;
    isSpecialOfferModalClose: boolean;
}

export const RevealerContext = React.createContext({
    revealerToggle: () => {},
});
class RevealerBase extends React.PureComponent<RevealerProps, RevealerState> {
    constructor(props: RevealerProps) {
        super(props);
        this.state = {
            childsWithoutContent: [],
            expand: false,
            disableWidget: true,
            showPopupDelayState: false,
            showPopup: false,
            reachedScrollPopupHeight: false,
            exitIntentPopupState: false,
            feedbackPopup: false,
            isSpecialOfferModalClose: false,
        };
    }

    public componentDidMount() {
        const { options } = this.props;

        this.setState({ disableWidget: !isClientLoggedIn() });

        // Delay in popup option specified per popup
        if (options.autoPopupDelay) {
            setTimeout(() => {
                this.setState({ showPopupDelayState: true });
            }, options.autopopupDelayCount);
        }
        // Options for not opening popup twice (i.e. marketing)
        if (typeof localStorage !== "undefined") {
            const popupSavedState = localStorage.getItem(`popupSavedState-${this.props.id}`);
            if (popupSavedState === "show" && options.saveStatePopup) {
                this.setState({ showPopup: true });
            } else if (popupSavedState === "hidden") {
                this.setState({ showPopup: false });
            }
            if (popupSavedState === "show" && !options.saveStatePopup) {
                localStorage.removeItem(`popupSavedState-${this.props.id}`);
            }
        }
        // Option for the exit intent with an even listener
        // We should use useEffect in the project with proper cleanups
        if (options.exitIntentPopup) {
            window.addEventListener("mouseout", (event) => {
                if (!event.relatedTarget && options.exitIntentPopup) {
                    this.setState({ exitIntentPopupState: true });
                } else if (!this.state.exitIntentPopupState) {
                    this.setState({ exitIntentPopupState: false });
                }
            });
            window.addEventListener("touchend", (event) => {
                if (event && options.exitIntentPopup) {
                    this.setState({ exitIntentPopupState: true });
                } else if (!this.state.exitIntentPopupState) {
                    this.setState({ exitIntentPopupState: false });
                }
            });
        }
        // Options for determine the scrollHeight when showing popup
        // Later on this will be enabled with the elements on that spot
        // i.e. trigger popup when object X has reached.
        window.addEventListener("scroll", () => this.setState({ reachedScrollPopupHeight: options.autoPopupOnScroll && window.pageYOffset >= options.autoPopupOnScrollHeight }));

        if (options.openByDefault) {
            this.setState({ expand: true });
        }
        if (options.openOnUrlIdMatch) {
            this.openModalOnUrlId();
        }
    }
    componentDidUpdate(prevProps: RevealerProps) {
        const {
            userInterfaceState: { specialSearchFacet },
        } = this.props;
        if (specialSearchFacet?.isSpecialModalOpen) {
            this.setState({ isSpecialOfferModalClose: true });
        }
    }
    public UNSAFE_componentWillReceiveProps(nextProps: RevealerProps) {
        const { options, id, myEnvState, revealerId } = this.props;
        if (!isEqual(revealerId, nextProps.revealerId) && options.useAsAccordian) {
            const expand = id === nextProps.revealerId ? !this.state.expand : false;
            this.setState({ expand });
        }
        if (myEnvState.confirmingShoppingCart) {
            this.setState({ feedbackPopup: true });
        }
    }
    /* jscpd:ignore-start */
    // eslint-disable-next-line max-lines-per-function
    public render() {
        const {
            isMyEnvWidget,
            childs,
            options,
            showLess,
            showMore,
            className,
            currentLocale,
            permission,
            key,
            mode,
            root,
            item,
            initialItem,
            onChange,
            friendlyUrl,
            formAlerts,
            useConditionalStepFlow,
            useCrpProps,
            accommodationType,
            resort,
            unit,
            site,
        } = this.props;
        const { disableWidget, childsWithoutContent, expand, feedbackPopup, exitIntentPopupState, isSpecialOfferModalClose } = this.state;
        const hideWidget = setOpacityOnHide(options);
        let children = options.useDynamicContent ? this.passFunctionToChildProps(childs) : childs;
        const isConditionalOptions: boolean = options.enableCancellationFundAddOn;
        const isChildDisabled = !!(
            (disableWidget && hideWidget) ||
            (options.useDynamicContent && children.length === childsWithoutContent.length) ||
            (isConditionalOptions && !useConditionalStepFlow) ||
            (!this.state.disableWidget && hideWidget && localStorage.getItem("isFrontEndEditable") === "false")
        );

        if (options.useAsFeedbackPopup && isMyEnvWidget && !feedbackPopup) {
            return null;
        }

        if (isMyEnvWidget || (useCrpProps && accommodationType) || (useCrpProps && resort) || (useCrpProps && unit)) {
            if (accommodationType) {
                children = injectPropIntoElementTrees(children, { isMyEnvWidget, ...(useCrpProps && accommodationType ? { accommodationType, useCrpProps } : {}) });
            }
            if (resort) {
                children = injectPropIntoElementTrees(children, { isMyEnvWidget, ...(useCrpProps && resort ? { resort, useCrpProps } : {}) });
            }
            if (unit) {
                children = injectPropIntoElementTrees(children, { isMyEnvWidget, ...(useCrpProps && unit ? { unit, useCrpProps } : {}) });
            }
        }

        const animationControl = `${options.showAnimate ? "animated " + options.animateProperties : ""}`;
        const modalTitleLocalized = options && options.modalTitle ? options.modalTitle.find((title) => title.locale === currentLocale.locale) : null;

        const moreIcon = options.iconPropertiesForMore && this.renderToggleIcon(options.iconPropertiesForMore);
        const lessIcon = options.iconPropertiesForLess && this.renderToggleIcon(options.iconPropertiesForLess);

        const localizedCloseText = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedCloseText })?.closeLabel || "";
        const modalLocalizedTitle = modalTitleLocalized?.label || options.ModalTitle;

        const showMoreIcon =
            options && options.plusIcon && !options.iconPropertiesForMore ? (
                <FontAwesome
                    name="plus"
                    className={`${options.iconToRight ? "ml-2" : "mr-1"} ${
                        options.iconColor && options.iconColor.indexOf("theme") > -1
                            ? `color-${options.iconColor}`
                            : options.iconColor && options.iconColor.indexOf("rgba") === -1
                            ? "icon-" + options.iconColor
                            : ""
                    }`}
                    style={{ color: options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined }}
                />
            ) : (
                moreIcon
            );
        const showLessIcon =
            options && options.plusIcon && !options.iconPropertiesForLess ? (
                <FontAwesome
                    name="minus"
                    className={`${options.iconToRight ? "ml-2" : "mr-1"} ${
                        options.iconColor && options.iconColor.indexOf("theme") > -1
                            ? `color-${options.iconColor}`
                            : options.iconColor && options.iconColor.indexOf("rgba") === -1
                            ? "icon-" + options.iconColor
                            : ""
                    }`}
                    style={{ color: options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined }}
                />
            ) : (
                lessIcon
            );

        let useLink;
        if (options.completeTextClickable) {
            useLink = <a className="area-clickable" onClick={this.toggle} />;
        } else if (options.toggleButton) {
            useLink = (
                <a
                    // eslint-disable-next-line max-len
                    className={`toggle-link toggles ${options.useAsButton ? "toggle-button button button--m button--primary" : ""} ${options.toggleBehavior ? options.toggleBehavior : ""}`}
                    onClick={this.toggleBlock}
                    data-toggle-target={options.ElementToToggle ? options.ElementToToggle : undefined}
                >
                    {options.plusIcon && !options.iconToRight ? (expand ? showLessIcon : showMoreIcon) : undefined}
                    <span className="toggle-text">{expand ? showLess : showMore}</span>
                    {this.renderSelectedCriteriaCount()}
                    {options.plusIcon && options.iconToRight ? (expand ? showLessIcon : showMoreIcon) : undefined}
                </a>
            );
        } else {
            useLink = (
                <div className={`reveal-link-wrapper ${options.borderAndShadow ? "add-revealer-shadow" : ""} ${options.iconToRight ? "right-reveal" : ""}`}>
                    <div className={`add-revealer-border ${className} ${options.borderAndShadow && options.shadowHeight ? `revealer-shadow-height-${options.shadowHeight}` : ""}`}></div>
                    <a className={"toggle-link"} onClick={options.useAsAccordian ? this.toggleAccordion : this.toggle}>
                        {options.plusIcon && !options.iconToRight ? (expand ? showLessIcon : showMoreIcon) : undefined}
                        <span className="toggle-text">{expand ? showLess : showMore}</span>
                        {options.plusIcon && options.iconToRight ? (expand ? showLessIcon : showMoreIcon) : undefined}
                    </a>
                </div>
            );
        }
        const useButton = (
            <Button className="toggle-button button button--m button--primary" onClick={this.toggle}>
                {options.plusIcon && !options.iconToRight ? (expand ? showLessIcon : showMoreIcon) : undefined}
                <span className="toggle-text">{expand ? showLess : showMore}</span>
                {this.renderSelectedCriteriaCount()}
                {options.plusIcon && options.iconToRight ? (expand ? showLessIcon : showMoreIcon) : undefined}
            </Button>
        );
        // Only for show/hide functionality of revealer childs
        if (expand && options.hideFirstContent) {
            children = children.slice(1);
        }

        const useAsTextButton = (
            <div key={0}>
                {" "}
                {(children[0] as any)?.variable ? (
                    <div className="input-container" key={"" + (children[0] as any).variable}>
                        <GenericInput
                            key={key}
                            spec={children[0] as any}
                            mode={mode!}
                            permission={permission}
                            root={root}
                            item={item}
                            initialItem={initialItem}
                            value={(children[0] as any).variable ? item[(children[0] as any).variable] : undefined}
                            onChange={onChange!}
                            friendlyUrl={friendlyUrl}
                            alerts={formAlerts}
                            validate={this.props.validate}
                        />
                        {getValidationMessage(this.props, children[0])}
                    </div>
                ) : (
                    children[0]
                )}
            </div>
        );
        if (options.simpleToggle) {
            return (
                <React.Fragment>
                    <section className={`toggle revealer-shadow ${hideWidget} ${className} ${isChildDisabled && "disable-revealer"}`}>
                        {options.useAsButton ? useButton : useLink}
                        {!options.toggleButton ? (
                            <Collapse isOpen={expand}>
                                {children.map((child, ind) => (
                                    <div key={ind} className="items">
                                        {(child as any).variable ? (
                                            <div className="input-container" key={"" + (child as any).variable}>
                                                <GenericInput
                                                    key={key}
                                                    spec={child as any}
                                                    mode={mode!}
                                                    permission={permission}
                                                    root={root}
                                                    item={item}
                                                    initialItem={initialItem}
                                                    value={(child as any).variable ? item[(child as any).variable] : undefined}
                                                    onChange={onChange!}
                                                    friendlyUrl={friendlyUrl}
                                                    alerts={formAlerts}
                                                    validate={this.props.validate}
                                                />
                                                {getValidationMessage(this.props, child)}
                                            </div>
                                        ) : (
                                            child
                                        )}
                                    </div>
                                ))}
                            </Collapse>
                        ) : null}
                    </section>
                    {options.hideFirstContent && !expand && useAsTextButton}
                </React.Fragment>
            );
        }
        let isModalOpen;
        if (isSpecialOfferModalClose) {
            isModalOpen = false;
        } else {
            isModalOpen = (options.autoPopup || (options.exitIntentPopup && exitIntentPopupState) ? !expand : expand) && !isChildDisabled;
        }
        const useModal = (
            <Modal
                isOpen={isModalOpen}
                toggle={
                    !options.disableToggle
                        ? this.toggle
                        : () => {
                              return;
                          }
                }
                id={StringUtil.convertToDocumentQuerySelectable(options.name)?.toLowerCase()}
                className={classNames("items", className, {
                    [`popup-notification--${options.notificationPosition}`]: options.notificationPopup,
                    [`modal--${options.popUpPosition}`]: !options.notificationPopup && !options.useAsFeedbackPopup && !!options.popUpPosition,
                })}
                centered={options.popupCentered}
                wrapClassName={`${options.autoPopup || options.exitIntentPopup ? "popup-hidden" : ""} ${options.notificationPopup ? "popup-notification" : ""}`}
                contentClassName={options.showAnimate ? animationControl : ""}
                size={options.useAsModal ? options.modalSize : "lg"}
                onClosed={options.saveStatePopup ? this.togglePopupLocalstorage : undefined}
                backdrop={options.notificationPopup ? false : true}
            >
                <ModalHeader tag="div" className={classNames({ "empty-modal-title": !(modalTitleLocalized?.label || options.ModalTitle) })}>
                    {modalLocalizedTitle && <h4>{modalLocalizedTitle}</h4>}
                    <button className={classNames("popup-close close", { "close-text": !!localizedCloseText })} aria-hidden="true" onClick={this.toggle}>
                        <FontAwesome name="close" />
                        {localizedCloseText}
                    </button>
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardBody>
                            {children.slice(options.repeatFirstContentInModal ? 0 : 1).map((child, ind) => (
                                <div key={ind} className="items">
                                    {(child as any).variable ? (
                                        <div className="input-container" key={"" + (child as any).variable}>
                                            <GenericInput
                                                key={key}
                                                spec={child as any}
                                                mode={mode!}
                                                permission={permission}
                                                root={root}
                                                item={item}
                                                initialItem={initialItem}
                                                value={(child as any).variable ? item[(child as any).variable] : undefined}
                                                onChange={onChange!}
                                                friendlyUrl={friendlyUrl}
                                                alerts={formAlerts}
                                                validate={this.props.validate}
                                            />
                                            {getValidationMessage(this.props, child)}
                                        </div>
                                    ) : (
                                        <RevealerContext.Provider value={this.revealerContextState}>{child}</RevealerContext.Provider>
                                    )}
                                </div>
                            ))}
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>
        );
        const useToggle = (
            <Collapse isOpen={expand}>
                <Card>
                    <CardBody>
                        {children.slice(1).map((child, ind) => (
                            <div key={ind} className="items">
                                {(child as any).variable ? (
                                    <div className="input-container" key={"" + (child as any).variable}>
                                        <GenericInput
                                            key={key}
                                            spec={child as any}
                                            mode={mode!}
                                            permission={permission}
                                            root={root}
                                            item={item}
                                            initialItem={initialItem}
                                            value={(child as any).variable ? item[(child as any).variable] : undefined}
                                            onChange={onChange!}
                                            friendlyUrl={friendlyUrl}
                                            alerts={formAlerts}
                                            validate={this.props.validate}
                                        />
                                        {getValidationMessage(this.props, child)}
                                    </div>
                                ) : (
                                    child
                                )}
                            </div>
                        ))}
                    </CardBody>
                </Card>
            </Collapse>
        );
        // Exit intent popup
        if (!options.autoPopup && options.exitIntentPopup && this.state.exitIntentPopupState && !isChildDisabled) {
            return useModal;
        }
        // Default auto popup | Delay popup | Scroll popup
        if (!this.state.showPopup && options.autoPopup && !isChildDisabled) {
            if (options.autoPopupDelay && !options.autoPopupOnScroll) {
                return this.state.showPopupDelayState && !this.state.reachedScrollPopupHeight ? useModal : null;
            } else if (!options.autoPopupDelay && options.autoPopupOnScroll) {
                return options.autoPopupOnScroll && !this.state.showPopupDelayState ? (this.state.reachedScrollPopupHeight ? useModal : null) : useModal;
            }
            return useModal;
        }
        // This is one of our first widgets and code improvement is much needed. Created: MCMS-3651
        if ((showLess && showMore !== "") || (!options.autoPopup && !options.exitIntentPopup)) {
            return (
                <div className={`revealer-wrapper ${className} ${isChildDisabled && "disable-revealer"}`}>
                    {options.toggleButton ? (
                        useLink
                    ) : (
                        <section className={`toggle ${hideWidget}`}>
                            {options.useAsSingleButton && options.useAsModal ? <div /> : useAsTextButton}
                            {options.useAsModal ? useModal : useToggle}
                            {options.useAsButton ? useButton : useLink}
                        </section>
                    )}
                </div>
            );
        }
        return false;
    }
    /* jscpd:ignore-end */
    private renderToggleIcon = (name: string) => {
        const { options } = this.props;
        return (
            <FontAwesome
                name={name}
                className={`fontawesome-${name} icon ${options.iconToRight ? "ml-2" : "mr-1"} ${
                    options.iconColor && options.iconColor.indexOf("theme") > -1
                        ? `color-${options.iconColor}`
                        : options.iconColor && options.iconColor.indexOf("rgba") === -1
                        ? "icon-" + options.iconColor
                        : ""
                }`}
                style={{ color: options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined }}
            />
        );
    };
    private togglePopupLocalstorage = () => {
        const { options } = this.props;
        if (options.saveStatePopup) {
            localStorage.setItem(`popupSavedState-${this.props.id}`, !this.state.showPopup ? "show" : "hidden");
            this.setState({ showPopup: !this.state.showPopup });
        }
    };

    private openModalOnUrlId = () => {
        const { options } = this.props;
        if (window.location.href.includes(options.name)) {
            this.toggle();
        }
    };

    private toggleBlock = () => {
        const { options } = this.props;
        const toggleBlock = document.querySelectorAll("[data-toggle-element=" + `'${options.ElementToToggle}'` + "]");
        const toggleBtns = document.querySelectorAll("[data-toggle-target=" + `'${options.ElementToToggle}'` + "]");
        Array.from(toggleBlock).forEach((element: any) => {
            if (element && !element!.classList.contains("toggle-block-hidden")) {
                element!.classList.add("toggle-block-hidden");
                element!.classList.remove("toggle-block-visible");
            } else {
                element!.classList.remove("toggle-block-hidden");
                element!.classList.add("toggle-block-visible");
            }
            Array.from(toggleBtns).forEach((button: any) => {
                if (!element!.classList.contains("toggle-block-hidden")) {
                    button.classList.add("active");
                } else {
                    button.classList.remove("active");
                }
            });
        });
        this.setState((state) => ({
            expand: !state.expand,
        }));
    };

    private toggle = () => {
        this.setState((state) => ({
            expand: !state.expand,
            isSpecialOfferModalClose: false,
        }));
        const {
            userInterfaceState: { specialSearchFacet },
        } = this.props;
        if (specialSearchFacet?.isSpecialModalOpen) {
            const action: UserInterfaceAction = {
                type: ActionType.UserInterface,
                actionType: UserInterfaceActionType.TOGGLE_SPECIAL_MODAL,
                payload: {
                    specialSearchFacet: {
                        isSpecialModalOpen: false,
                    },
                },
            };
            this.props.dispatchAction(action);
        }
    };

    revealerContextState = {
        revealerToggle: this.toggle,
    };

    private toggleAccordion = () => {
        const { id, dispatchAction } = this.props;
        const action: RevealerAction = {
            type: ActionType.RevealerClick,
            payload: id,
        };
        if (this.props.id !== this.props.revealerId) {
            dispatchAction(action);
        } else {
            this.toggle();
        }
    };

    private renderSelectedCriteriaCount = (): JSX.Element | null => {
        const { options, selectedCriteriaCount } = this.props;
        const { expand } = this.state;

        return (!expand && options.showFilterCountInShowMoreButton && selectedCriteriaCount && <span className="count">{selectedCriteriaCount}</span>) || null;
    };

    /**
     * Function that can be passed onto the children when dynamic content is enabled.
     * If none of the children have dynamic content, the widget is disabled.
     */
    private addChildWithoutContent = (child: JSX.Element) => {
        const { childsWithoutContent } = this.state;
        const updatedChildsWithoutContent = [...childsWithoutContent];

        if (childsWithoutContent.findIndex((currentChild) => currentChild?.props?.options._id === child?.props?.options._id) === -1) {
            updatedChildsWithoutContent.push(child);
            this.setState({ childsWithoutContent: updatedChildsWithoutContent });
        }
    };

    private removeChildFromChildWithoutContentArray = (child: JSX.Element) => {
        const { childsWithoutContent } = this.state;
        const updatedChildsWithoutContent = childsWithoutContent.filter((currentChild) => currentChild?.props?.options._id !== child?.props?.options._id);
        this.setState({ childsWithoutContent: updatedChildsWithoutContent });
    };

    /**
     * Function that is used to add the addChildWithoutContent function to the props of the children by cloning them and adding the new props.
     */
    private passFunctionToChildProps = (childs: JSX.Element[]): JSX.Element[] =>
        childs.map((child: JSX.Element) =>
            React.cloneElement(child, {
                notifyParentNoDynamicContentWasFound: this.addChildWithoutContent,
                notifyParentTheDynamicContentFoundAgain: this.removeChildFromChildWithoutContentArray,
                flexboxOptions: this.props.flexboxOptions,
            })
        );
}

function mapStateToProps(state: State): RevealerStoreProps {
    return {
        revealerId: state.revealerState.revealerId,
        useConditionalStepFlow: state.prevNextState.useConditionalStepFlow,
        selectedCriteriaCount: state.userInterfaceState.criteriaPanel?.selectedCriteriaCount,
        myEnvState: state.myEnvState,
        userInterfaceState: state.userInterfaceState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<RevealerAction | UserInterfaceAction>): RevealerDispatchProps {
    return { dispatchAction: dispatch };
}

const RevealerRaw = connect<RevealerStoreProps, RevealerDispatchProps>(mapStateToProps, mapDispatchToProps)(RevealerBase);

export const Revealer = wrapProps<RevealerBaseProps>(CMSAware<RevealerBaseProps>(RevealerRaw));
